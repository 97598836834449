<template>
  <div>
    <shipblu-card class="mb-6 mt-2 p-5" :cardLoading="trackingsLoading">
      <!-- Img Row -->
      <div class="flex flex-wrap items-center mb-base">
        <vs-avatar :src="activeUserInfo.photoURL" size="70px" class="mr-4 mb-4" />
        <div>
          <vs-button v-if="activeUserInfo.userRole !== 'merchant'" @click="addFeature" class="mr-4 sm:mb-0 mb-2">{{$t('Upload photo')}}</vs-button>
          <vs-button v-if="activeUserInfo.userRole !== 'merchant'" @click="addFeature" type="border" color="danger">{{$t('Remove')}}</vs-button>
          <p v-if="activeUserInfo.userRole !== 'merchant'" class="text-sm mt-2">{{$t('Allowed JPG, GIF or PNG. Max size of 800kB')}}</p>
        </div>
      </div>
      <!-- Info -->
      <!-- <div class="vx-row mb-base sm:flex">
        <label class="vx-col w-1/2 font-semibold">Name</label>
        <h4 class="vx-col w-1/2 font-semibold">{{activeUserInfo.name}}</h4>
      </div> -->
      <div class="vx-row mb-base">
        <label class="vx-col lg:w-1/3 md:w-full font-semibold">Name</label>
        <h5 class="vx-col w-full font-semibold">{{activeUserInfo.displayName}}</h5>
      </div>
      <div class="vx-row mb-base">
        <label class="vx-col lg:w-1/3 md:w-full font-semibold">Email</label>
        <h5 class="vx-col w-full font-semibold">{{activeUserInfo.email}}</h5>
      </div>
      <!-- Save & Reset Button -->
      <div class="flex flex-wrap items-center justify-end">
        <vs-button v-if="activeUserInfo.userRole !== 'merchant'" @click="addFeature" class="ml-auto mt-2">{{$t('Save Changes')}}</vs-button>
        <vs-button v-if="activeUserInfo.userRole !== 'merchant'" @click="addFeature" class="ml-4 mt-2" type="border" color="warning">{{$t('Reset')}}</vs-button>
      </div>
    </shipblu-card>

    <!-- Auto Processing Order -->
    <div v-if="activeUserInfo.userRole === 'merchant'">
      <p class="font-medium text-2xl text-black">{{ $t('Order Settings') }}</p>
      <shipblu-card :cardLoading="trackingsLoading" class="mt-4 mb-7 p-5">
        <p class="text-blue-900 font-medium text-xl">{{ $t('Pickup Request Default') }}</p>
        <div class="flex justify-between mt-5 items-center">
          <p class="font-semibold text-xl" :class="merchant.auto_process_orders && merchant.auto_process_orders !== null ? 'text-primary' : 'text-darkgray'">{{ $t('Automatic Pickup, Return, Collection Request') }}</p>
          <vs-switch @click="updateAutoProcessOrders(!merchant.auto_process_orders)" v-model="merchant.auto_process_orders" />
        </div>
        <p class="capitalize text-darkgray font-semibold mt-2 leading-tight">{{ $t('Your newly created orders will be on "pickup requested - return requested - collection requested" status automatically') }}</p>
      </shipblu-card>
    </div>

        <!-- Instant Payout Settings -->
    <div v-if="activeUserInfo.userRole === 'merchant'" class="hidden">
      <p class="font-medium text-2xl text-black">{{ $t('Instant Payout Settings') }}</p>
      <shipblu-card :cardLoading="trackingsLoading" class="mt-4 mb-7 p-5">
        <p class="text-blue-900 font-medium text-xl">{{ $t('Instant Payout Settings') }}</p>
        <div class="flex justify-between mt-5 items-center">
          <p class="font-semibold text-xl" :class="merchant.auto_process_orders && merchant.auto_process_orders !== null ? 'text-primary' : 'text-darkgray'">{{ $t('Automatic Daily Transfer for the confirmed collected cash.') }}</p>
          <vs-switch @click="updateAutoProcessOrders(!merchant.auto_process_orders)" v-model="merchant.auto_process_orders" />
        </div>
        <p class="capitalize text-darkgray font-semibold mt-2 leading-tight">{{ $t('You will receive the confirmed cash collected from your delivery orders, with a fee of 1.5%.') }}</p>
      </shipblu-card>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
import common from '../../../assets/utils/common.js'

export default {
  data () {
    return {
      merchant: {},
      trackingsLoading: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    addFeature () {
      this.$vs.notify({
        color:'warning',
        title:'Warning',
        text: 'We are still working on adding this feature, check back soon!',
        position: 'top-center'
      })
    },
    updateAutoProcessOrders (value) {
      const merchantObj = {
        auto_process_orders: value
      }
      this.trackingsLoading = true
      sendRequest(true, false, this, `api/v1/merchants/${this.merchant.id}/`, 'patch', merchantObj, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Pickup Request preference updated successfully'],
            position: 'top-center'
          })
          this.loadMerchant()
        }
      )
    },
    loadMerchant () {
      this.trackingsLoading = true
      sendRequest(true, false, this, `api/v1/merchants/${this.merchant.id}/`, 'get', null, true,
        (response) => {
          this.trackingsLoading = false
          localStorage.setItem('merchant', JSON.stringify(response.data))
          this.merchant = JSON.parse(localStorage.getItem('merchant'))
        }
      )
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      merchantData.then(results => {
        this.merchant = results.merchantData
      })
    }
  }
}
</script>
