<template>
  <vs-tabs v-model="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? $t('General') : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-general />
      </div>
    </vs-tab>
    <vs-tab v-if="activeUserInfo.userRole==='merchant'" icon-pack="feather" icon="icon-link-2" :label="!isSmallerScreen ? $t('Merchant') : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-merchant />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? $t('Change Password') : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-change-password />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'
import UserMerchant from './UserMerchant.vue'

export default {
  components: {
    UserSettingsGeneral,
    UserSettingsChangePassword,
    UserMerchant
  },
  data () {
    return {
      activeTab: 0,
      activeTabNameDic: {'general': 0, 'merchant': 1, 'change-password': 2},
      activeTabDic: {0: 'general', 1: 'merchant', 2: 'change-password'}
    }
  },
  watch: {
    '$route.params.tab' () {
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        }
      }).catch(() => {})
    },
    'activeTab' () {
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        }
      }).catch(() => {})
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    },
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  created () {
    this.activeTab = this.activeTabNameDic[this.$route.params.tab]
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
